<template>

      <div class="finance" ref="finance">
        <div class="main" style="padding-left: 0px;">
          <div class="abb-canvas">
            <div class="abb-canvas-aside" v-show="leftmenu">
              <div class="abb-aside-search">
                <a-input-search placeholder="请输入搜索关键字" v-model="searchValue" @search="onSearch" />
                <span class="shousuo" @click="handlerisCollapse">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>菜单收起</span>
                    </template>
                    <img src="@/assets/icon/icon/low.svg" alt="">
                  </a-tooltip>

                </span>
              </div>
              <a-tree class="deptTree" :tree-data="filterDeptData" v-if="filterDeptData.length > 0" default-expand-all
                show-icon :default-selected-keys="defaultSelectedKeys" :replaceFields="replaceFields" @select="onSelect">
                <i slot="switcherIcon" class="lowcode icon-right"></i>
                <i slot="headquarters" class="lowcode icon-fangwu"></i>
              </a-tree>
            </div>
            <div class="abb-canvas-aside-close" v-show="!leftmenu">
              <span class="shousuo" @click="handlerisCollapse">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>菜单展开</span>
                  </template>
                  <img src="@/assets/icon/icon/low.svg" alt="">
                </a-tooltip>
              </span>
              <span class="deptName">
                {{ currentSelectDeptInfo.FULLNAME }}
              </span>
            </div>
            <div class="abb-canvas-container">
              <ul>
                <li v-for="(item, index) in tabList2"  :key="index" @click="btnSite(index,item)" :style="{ 'background': indexSite == index ? '#000000' : 'none', 'opacity': indexSite == index ? 1 : 0.65,'border':indexSite == index ?'1px #3e6db0 solid':'black' }">
                  {{ item }}
                </li>
              </ul>
              <div class="viewMain">
                <div class="viewUp">
                  <div>
                    <powerStation></powerStation>
                  </div>
                  <div>
                    <reduction></reduction>
                  </div>
                </div>
                <div class="viewDown">
                  <div class="left" style="display: block;">
                    <newcont></newcont>
                  </div>
                  <div class="cont">
                    <graphleft></graphleft>
                  </div>
                  <div class="right" style="display: block;">
                    <graphcont></graphcont>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

</template>
  
<script>
import wrapperAll from "@/views/bigscreen/subgroup/screen";
import topTitle from "./title";
import powerStation from "./powerStation";
import reduction from "./reduction";
import graphleft from "./graphleft";
import graphcont from "./graphcont";
import graphright from "./graphright";
import newcont from "./new";
export default {
  data() {
    return {
      isFullMode: false,
      nameSite: '运维管理驾驶舱',
      tabList: ['本日', '本月', '本年'],
      tabList2: [ '','本月', '本年'],
      leftmenu: true,
      options: [{
        value: '221027388',
        label: '昆山园区'
      }, {
        value: '221027423',
        label: '车间北配电房'
      }, {
        value: '221027422',
        label: '行政楼配电房'
      }, {
        value: '221027390',
        label: '能源楼配电房'
      },],
      value: '221027388',
      changeValue: '221027388',
      // 
      showd: false,
      dialogVisible: false,
      replaceFields: {
        children: 'children',
        title: 'FULLNAME',
        key: 'CODE'
      },
      panellist: [],
      selectDeptInfo: {},//当前Dept信息
      searchValue: ''
    }
  },
  components: {
    newcont,
    wrapperAll,
    topTitle,
    powerStation,
    reduction,
    graphleft,
    graphcont,
    graphright
  },

  computed: {
    indexSite(){
      let index = 0
      if(this.$store.state.financetype == '按天'){
        index = 1
      }else if(this.$store.state.financetype == '按月'){
        index = 1
      }else if(this.$store.state.financetype == '按年'){
        index =2
      }
      return index
    },
    showimgconfig() {
      return this.$store.state.showimgconfig
    },
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo
    },
    allwidget() {
      return this.$store.state.allwidget
    },

    // Dept数据
    allDeptData() {
      return this.$store.state.allDeptData
    },

    filterDeptData() {
      var value = this.searchValue
      if (!value) {
        return this.allDeptData
      }

      var arrList = [];//树结构扁平化处理
      this.deepChildren(this.allDeptData, arrList)
      var arr = arrList.filter(item => {
        return item.FULLNAME.indexOf(value) != -1
      })
      if (arr.length > 0) {
        return arr
      }

      return this.allDeptData
    },
    // // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    },
    defaultSelectedKeys() {
      return [this.currentSelectDeptInfo.CODE]
    },
    // 是否请求驾驶舱数据
    isRequestPanelInfo() {
      return this.$store.state.isRequestPanelInfo
    },
  },
  watch: {
    showimgconfig(val) {
      if (val) {
        this.dialogVisible = val
        console.log(val)
      }
    },
    isRequestPanelInfo(val) {
      if (val) {
        this.getpanelInfo('update')
      }
    },

  },
  methods: {
    btnSite(index,item) {
     
      console.log(item)
      if(item == "本日"){
        this.$store.commit("setfinancetype", "按天")
      }else if(item =="本月"){
        this.$store.commit("setfinancetype", "按月")
      }else if(item =="本年"){
        this.$store.commit("setfinancetype", "按年")
      }
      
    },
    delCard(value) {
      console.log(value)
      this.changeValue = value
      this.$store.commit("setbigdeptcode", value)
      // this.$store.commit("bigdeptcode", value)

    },
    opendialog() {
      this.showd = !this.showd
    },
    handleClose() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    cancel() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    save() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    deepChildren(arr, brr) {
      arr.forEach(item => {
        if (!!item.children) {
          this.deepChildren(item.children, brr)
        }
        var { children, ...obj } = item
        brr.push(obj)
      })
    },

    //获取驾驶舱信息
    getpanelInfo(str = '') {
      const url = Config.dev_url + "/api-apps/panel/list";
      var data = {
        location: this.selectDeptInfo.FULLNAME,//当前机构名
        type: this.currentSelectMenuInfo.name//当前菜单名称
      }
      this.$axios.get(url, { params: data }).then((res) => {
        if (res.data.code == 200) {
          var result = res.data.data.data
          result.forEach(item => {
            var tempArr = JSON.parse(item.value)
            item.value = tempArr || []
          })
          console.log(result)
          var compare = function (obj1, obj2) {
            var val1 = parseInt(obj1.index);
            var val2 = parseInt(obj2.index);
            if (val1 < val2) {
              return -1;
            } else if (val1 > val2) {
              return 1;
            } else {
              return 0;
            }
          }
          console.log(result.sort(compare));
          this.panellist = result.sort(compare)
          if (result.length > 0 && str != 'update') {
            this.$store.commit("setallwidget", result[0])
          }
          else {
            if (result.length == 0) {
              this.$store.commit("setallwidget", {})
              return
            }
            var wId = this.allwidget.id
            var target = result.filter(item => item.id == wId)
            if (target.length > 0) {
              this.$store.commit("setallwidget", target[0])
            } else {
              this.$store.commit("setallwidget", result[0])
            }
          }
        }
        else {
          this.panellist = []
        }
        this.$store.commit("setpanellist", this.panellist)

      }).catch((err) => {
        this.panellist = []
      }).finally(() => {
        this.$store.commit("setcurrentSelectDeptInfo", this.selectDeptInfo)
        this.$store.commit("setisRequestPanelInfo", false)
      })
    },

    // 展开折叠树
    handlerisCollapse() {
      this.leftmenu = !this.leftmenu
    },
    // 机构搜索
    onSearch(value) {
      console.log(value);
    },
    // 获取一级树数据
    getFirstTreeData() {
      const url = Config.dev_url + "/api-apps/app/DEPT/findTree";
      var data = {
        codekey: "CODE",
        pcodekey: "PCODE",
      }
      this.$axios.post(url, data).then(res => {
        var result = res.data || [];
        if (result.length == 0) {
          this.getTreeData()
        }
        result.forEach(item => {
          if (item.haschilrensize) {
            // this.getChildrenData(item).then(data=> {
            //   item.children = data
            // })
          }
        })
        this.$store.commit("setallDeptData", result)
        if (result.length > 0) {
          this.setDeptInfo(result[0])
        }
      }).catch((wrong) => {
        
      })
    },
    getTreeData() {
      // this.$store.state.currentuserinfo
      const url = Config.dev_url + "/api-apps/app/DEPT/findLayTree";
      var data = {
        codekey: "CODE",
        pcodekey: "PCODE",
      }
      this.$axios.post(url, data).then(res => {
        var result = res.data || [];
        result.forEach(item => {
          if (item.haschilrensize) {
            this.getChildrenData(item).then(data => {
              item.children = data
            })
          }
        })
        this.$store.commit("setallDeptData", result)
        if (result.length > 0) {
          this.setDeptInfo(result[0])
        }
      }).catch((wrong) => {
        this.$message.warning(wrong.msg)
      })
    },
    async getChildrenData(item) {
      return await new Promise((resolve) => {
        const url = Config.dev_url + "/api-apps/app/DEPT/findLayTree";
        var data = {
          PCODE_wheres: item.CODE,
          codekey: "CODE",
          pcodekey: "PCODE",
        }
        var result = [];
        this.$axios.post(url, data).then(res => {

          result = res.data || [];
          result.forEach(item => {
            if (item.haschilrensize) {
              this.getChildrenData(item).then(data => {
                item.children = data
              })
            }
          })
          return resolve(result);
        }).catch((wrong) => {
          return resolve(result);
          // this.$message.warning(wrong.msg)
        }).finally(() => {
          //  this.$forceUpdate()
        })
      })


    },
    // 选中的机构
    onSelect(selectedKeys, info) {
      var item = info.node.dataRef
      this.$store.commit("setfinanceView", item.DEPT);
    },
    // 通过机构CODE查询数据字典
    fetchDicByDept() {

    },
    //获取该应用下所有数据字典项
    getAppDictData() {
      // const dictionaryCode =
      //   [
      //     "QUARTER",//季度
      //   ]
      // const url = Config.dev_url + '/api-user/dicts/findByCodes';
      // this.$axios.post(url, { code: [...new Set(dictionaryCode)].join(",") }).then((res) => {
      //   this.currentSelectMenuInfo.appDictData = res.data
      // }).finally(() => {

      // })
    },

    init() {
      console.log(this.allDeptData)
      if (this.allDeptData.length == 0) {
        this.getAppDictData()
        this.getFirstTreeData()
      } else {
        this.selectDeptInfo = this.currentSelectDeptInfo
        this.getpanelInfo()
      }
    }
  },
  mounted() {
    document.body.style.overflowY = 'scroll'
    this.init()
  }
}
</script>
  
<style lang="less" scoped>
.bigheard {
  background: #1a2e45;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    display: flex;
    align-items: center;

    &>img {
      width: 71px;
      height: 27px;
    }
  }

}

.finance {
  width: 100%;
  height: calc(100% - 77px);
  background: rgb(26, 46, 69);
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
}

// ************
.viewMain {
  height: calc(100% - 57px);
  display: flex;
  flex-direction: column;

  .viewUp {
    flex: 1;
    display: flex;

    &>div:nth-child(1) {
      width: 52%;
      margin-right: 60px;
    }

    &>div:nth-child(2) {
      width: 48%;
      margin-left: 60px;
    }
  }

  .viewDown {
    flex: 1;
    display: flex;

    &>div {
      flex: 1;
    }
    .cont{
      margin: 0 76px;
    }
  }
}

.abb-canvas {
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;

  .abb-canvas-aside, .abb-canvas-aside-close {
    margin-right: 0px;
  }

  .abb-canvas-aside {
    width: 279px;
    height: inherit;
    background: #0e385f;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.50);
    box-sizing: border-box;

    .abb-aside-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      /deep/.ant-input-search {
        width: 232px;
      }

      .shousuo {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .deptTree {
      height: calc(100vh - 145px);
      overflow: auto;
    }

    /deep/.ant-tree {

      .ant-tree-icon__customize {
        margin-right: 8px;

      }

      .ant-tree-switcher_close {
        .icon-right {
          &::before {
            content: "\e76b";
          }
        }
      }

      .ant-tree-switcher_open {
        .icon-right {
          &::before {
            content: "\e76c";
          }
        }
      }

      .ant-tree-treenode-selected {
        background: rgba(208, 222, 255, 0.60);
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        .ant-tree-node-selected {
          background-color: transparent;

          span {
            color: #fff;
            opacity: 1 !important;
            font-size: 14px;
            font-weight: 400;
          }

        }

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 40px;
          top: 0;
          border-radius: 4px 0px 0px 4px;
        }
      }

      .ant-tree-node-content-wrapper {
        &:hover {
          background-color: transparent;
        }
      }



    }

  }

  .abb-canvas-aside-close {
    width: 48px;
    box-sizing: border-box;
    background: #0e385f;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.50);
    display: flex;
    justify-content: center;
    position: relative;
    color: #ffffff;

    .shousuo {
      position: absolute;
      top: 20px;
      left: 12px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .deptName {
      word-wrap: break-word;
      /*英文的时候需要加上这句，自动换行*/
      writing-mode: vertical-rl;
      /*从左向右 从右向左是 writing-mode: vertical-rl;*/
      writing-mode: tb-rl;
      /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
      text-align: center;
      padding: 0px;
      display: flex;
      -webkit-display: flex;
      flex-direction: column;
      justify-content: center;

      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
  }

  .abb-canvas-container {
    flex: 1;
    margin: 0.1rem 0.1rem 0.1rem 0.3rem;

    ul {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;

      li {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
        line-height: 22px;
        padding: 12px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 3px;
      }
    }
  }

}



/deep/.ant-tree li .ant-tree-node-content-wrapper {
  color: #fff !important;
  opacity: 0.65 !important;
}

/deep/.ant-tree-treenode-selected::before {
  background: #1cece8 !important;
}

/deep/.ant-tree-treenode-selected {
  background: #25527b !important;
}

/deep/.ant-tree-treenode-selected .ant-tree-node-selected span {
  color: #fff !important;
  opacity: 1 !important;
}

/deep/.abb-canvas .abb-canvas-aside .ant-tree .ant-tree-treenode-selected .ant-tree-node-selected {
  opacity: 1 !important;
}
.grouptitle{
  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}
</style>