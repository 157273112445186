<template>
  <div class="powerStation">
    <siteName :objname="objname"></siteName>
    <div class="contant">
      <div style="width: 366px;height: 364px;display: flex;justify-content: center;align-items: center;">
        <img src="../../../assets/icon/icon/cluster.png" alt="" style="position: absolute;">
        <div class="textPIN">
          <span>并网发电量 (kWh)</span>
          <span>{{ valueIndex }}</span>
        </div>
      </div>
      <ul style="margin-top: 10px;">
        <li v-for="(item, index) in textList" :key="index">
          <span>{{ item.name }}</span>
          <span v-if="(item.name).indexOf('元') > -1 ? true : false">￥{{ item.value }}</span>
          <span v-else>{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import siteName from "./site";
export default {
  name: 'powerStation',
  props: {},
  components: { siteName },
  data() {
    return {
      maindata:"",
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        TYPE1: "总发电量",
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      detailInfo: {
        chart1: {},
        chart2:{}
      },
      newarr1:[],
      valueIndex: '',
      objname: {
        name: 'Power Plant Overview',
        site: '电站信息'
      },
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
          if(this.currentSelectDeptInfo =="221027059"||this.currentSelectDeptInfo =="221027060"){
            this.getData3()
          }else{
            this.getData2()
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
          if(this.currentSelectDeptInfo =="221027059"||this.currentSelectDeptInfo =="221027060"){
            this.getData3()
          }else{
            this.getData2()
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
      
        if (oldVal) {
        
          this.getData();
          
          if(this.currentSelectDeptInfo =="221027059"||this.currentSelectDeptInfo =="221027060"){
            this.getData3()
          }else{
            this.getData2()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.financeView;
    },
    // 当前Dept信息
    textList(){
      let capacity = ""
      let time  = "—"
      if(this.currentSelectDeptInfo == '221027388'){
        capacity = 948
        time = "2017/4/1"
      }else if(this.currentSelectDeptInfo == '221027423'){
        capacity = 560
        time = "2017/4/1"
      }else if(this.currentSelectDeptInfo == '221027390'){
        capacity = 323
        time = "2018/5/11"
      }else if(this.currentSelectDeptInfo == '221027422'){
        capacity = 65
        time = "2017/4/1"
      }else if(this.currentSelectDeptInfo =="221027059"){
        capacity = 948
      }else if(this.currentSelectDeptInfo =="221027060"){
        capacity = 948
      }else if(this.currentSelectDeptInfo =="221027582"){
        capacity = 0
      }

      let arr = [
        {
          name: '并网日期',
          value: time
        },
        {
          name: '电站容量 (kWp)',
          value: capacity
        },
        {
          name: '净利润 (元)',
          value: this.toThousandsSeparator(parseInt(this.detailInfo.self)+parseInt(this.detailInfo.surplus))
        }
      ]
      // 利星行能源(昆山)  221027582
      // 利星行机械（扬州分公司） 221027586
      // 利星行机械（杭州分公司） 221027585
      // 昆山杰伦特 221027584
      // 昆山奔驰 221027583
      
      if(this.currentSelectDeptInfo =="221027582"){
        
        this.valueIndex = "580.63万"
        arr = [
        {
          name: '并网日期',
          value: '-'
        },
        {
          name: '电站容量 (kWp)',
          value: 9471
        },
        {
          name: '净利润 (元)',
          value: "360.3万"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027059"){
        // this.valueIndex = "23.91万"
        arr = [
        {
          name: '并网日期',
          value: '—'
        },
        {
          name: '电站容量 (kWp)',
          value: 10419
        },
        {
          name: '净利润 (元)',
          value: this.toThousandsSeparator(parseInt(this.detailInfo.self)+parseInt(this.detailInfo.surplus)+81721)
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027060"){
        // this.valueIndex = "23.91万"
        arr = [
        {
          name: '并网日期',
          value: '—'
        },
        {
          name: '电站容量 (kWp)',
          value: 10419
        },
        {
          name: '净利润 (元)',
          value: this.toThousandsSeparator(parseInt(this.detailInfo.self)+parseInt(this.detailInfo.surplus)+81721)
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027586"){
        
        this.valueIndex = "22.93万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/21'
        },
        {
          name: '电站容量 (kWp)',
          value: 362
        },
        {
          name: '净利润 (元)',
          value: "113,995"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027585"){
        this.valueIndex = "21.87万"
        arr = [
        {
          name: '并网日期',
          value: '2018/6/7'
        },
        {
          name: '电站容量 (kWp)',
          value: 345
        },
        {
          name: '净利润 (元)',
          value: "50,904"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027584"){
        this.valueIndex = "66.67万"
        arr = [
        {
          name: '并网日期',
          value: '2020/6/29'
        },
        {
          name: '电站容量 (kWp)',
          value: "1,080"
        },
        {
          name: '净利润 (元)',
          value: "228,142"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027583"){
        this.valueIndex = "13.68万"
        arr = [
        {
          name: '并网日期',
          value: '2017/12/27'
        },
        {
          name: '电站容量 (kWp)',
          value: 218
        },
        {
          name: '净利润 (元)',
          value: "62,766"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027593"){
        this.valueIndex = "23.44万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/5'
        },
        {
          name: '电站容量 (kWp)',
          value: 344
        },
        {
          name: '净利润 (元)',
          value: "42,698"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027591"){
        this.valueIndex = "52.15万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/6'
        },
        {
          name: '电站容量 (kWp)',
          value: 780
        },
        {
          name: '净利润 (元)',
          value: "288,773"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027592"){
        this.valueIndex = "65.68万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/5'
        },
        {
          name: '电站容量 (kWp)',
          value: "1,101"
        },
        {
          name: '净利润 (元)',
          value: "333,647"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027595"){
        this.valueIndex = "20.35万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/10'
        },
        {
          name: '电站容量 (kWp)',
          value: 308
        },
        {
          name: '净利润 (元)',
          value: "135,765"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027597"){
        this.valueIndex = "24.95万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/11'
        },
        {
          name: '电站容量 (kWp)',
          value: 397
        },
        {
          name: '净利润 (元)',
          value: "133,013"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027588"){
        this.valueIndex = "42.82万"
        arr = [
        {
          name: '并网日期',
          value: '—'
        },
        {
          name: '电站容量 (kWp)',
          value: 758
        },
        {
          name: '净利润 (元)',
          value: "81,721"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027589"){
        this.valueIndex = "81.23万"
        arr = [
        {
          name: '并网日期',
          value: '2019/6/5'
        },
        {
          name: '电站容量 (kWp)',
          value: "1,230"
        },
        {
          name: '净利润 (元)',
          value: "264,097"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027596"){
        this.valueIndex = "36.48万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/11'
        },
        {
          name: '电站容量 (kWp)',
          value: 797
        },
        {
          name: '净利润 (元)',
          value: "71,380"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027590"){
        this.valueIndex = "17.05万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/21'
        },
        {
          name: '电站容量 (kWp)',
          value: 256
        },
        {
          name: '净利润 (元)',
          value: "113,994"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027587"){
        this.valueIndex = "26.71万"
        arr = [
        {
          name: '并网日期',
          value: '2019/1/11'
        },
        {
          name: '电站容量 (kWp)',
          value: 399
        },
        {
          name: '净利润 (元)',
          value: "50,903"
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027594"){
        this.valueIndex = "64.55万"
        arr = [
        {
          name: '并网日期',
          value: '2018/5/10'
        },
        {
          name: '电站容量 (kWp)',
          value: "1,089"
        },
        {
          name: '净利润 (元)',
          value: "361,321"
        }
      ]
      }

      return  arr
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };;
      return obj[TYPE];
    },
    formatter: function(v) {
      v = v.toString();
      if (v >= 100000000000) {
        return v.substring(0, 5) / 10 + "亿";
      } else if (v >= 10000000000) {
        return v.substring(0, 4) / 10 + "亿";
      } else if (v >= 1000000000) {
        return v.substring(0, 3) / 10 + "亿";
      } else if (v >= 100000000) {
        return v.substring(0, 2) / 10 + "亿";
      } else if (v >= 10000000) {
        return v.substring(0, 4) + "万";
      } else if (v >= 1000000) {
        return v.substring(0, 3) + "万";
      } else if (v >= 100000) {
        return v.substring(0, 4)/ 100 + "万";
      } else if (v >= 10000) {
        return v.substring(0, 3) / 100 + "万";
      } else if (v >= 1000) {
        return v;
      } else if (v <= -100000000000) {
        return "-" + v.substring(1, 7) / 10 + "亿";
      } else if (v <= -100000000000) {
        return "-" + v.substring(1, 6) / 10 + "亿";
      } else if (v <= -10000000000) {
        return "-" + v.substring(1, 5) / 10 + "亿";
      } else if (v <= -1000000000) {
        return "-" + v.substring(1, 4) / 10 + "亿";
      } else if (v <= -100000000) {
        return "-" + v.substring(1, 3) / 10 + "亿";
      } else if (v <= -10000000) {
        return "-" + v.substring(1, 5) + "万";
      } else if (v <= -1000000) {
        return "-" + v.substring(1, 4) + "万";
      } else if (v <= -100000) {
        return "-" + v.substring(1, 3) + "万";
      } else if (v <= -10000) {
        return "-" + v.substring(1, 3) / 10 + "万";
      } else if (v <= -1000) {
        return v;
      } else {
        return v;
      }
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      // if(this.searchObj.ITEM == ""){
      //   this.detailInfo = {}
      //   var chart1 = {
      //     categoryData: [],
      //     seriesData: []
      //   }
      //   this.detailInfo.chart1 = chart1
      //   this.initEchart()
      //   return
      // }
      //   let data = {
      //     "deptCode": this.currentSelectDeptInfo.CODE,
      //     "assetNumber":this.searchObj.ITEM,
      //     "check": this.searchObj.TYPE1
      //   }
      let dept = ""
      if(this.currentSelectDeptInfo == "221027423"){
          dept = "221027388"
      }else if(this.currentSelectDeptInfo == "221027422"){
          dept = "221027388"
      }else if(this.currentSelectDeptInfo == "221027390"){
          dept = "221027388"
      }else if(this.currentSelectDeptInfo == "221027060"){
          dept = "221027388"
      }else if(this.currentSelectDeptInfo == "221027059"){
          dept = "221027388"
      }else{
          dept = this.$store.state.financeView
      }
      let data = {
        // deptCode:"221027091",
        deptCode: dept,
        check: this.searchObj.TYPE1,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      console.log(data);
      this.detailInfo = {};
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/statistics",
        method: "post",
        data,
      })
        .then((res) => { 
          let arr = ["221027586","221027585","221027584","221027583","221027593","221027591","221027592","221027595","221027597","221027588","221027589","221027596","221027590","221027587","221027594"]
          if(arr.includes(this.currentSelectDeptInfo)){
            
          }else{
            this.valueIndex = ""
            if(this.currentSelectDeptInfo == "221027423"){
              for(let item of res.data.data.seriesData){
                if(item.name =="车间"){
                  for(let item2 of item.value){
                    this.valueIndex = parseInt(this.valueIndex + item2)
                    this.maindata = this.valueIndex + item2
                  }
                  this.valueIndex = this.formatter(this.valueIndex)
                }
              }
            }else if(this.currentSelectDeptInfo == "221027422"){
              for(let item of res.data.data.seriesData){
                if(item.name =="行政楼"){
                  for(let item2 of item.value){
                    this.valueIndex = parseInt(this.valueIndex + item2)
                    this.maindata = this.valueIndex + item2
                  }
                  this.valueIndex = this.formatter(this.valueIndex)
                }
              }
            }else if(this.currentSelectDeptInfo == "221027390"){
              for(let item of res.data.data.seriesData){
                if(item.name =="能源楼"){
                  for(let item2 of item.value){
                    this.valueIndex = parseInt(this.valueIndex + item2)
                    this.maindata = this.valueIndex + item2
                  }
                  this.valueIndex = this.formatter(this.valueIndex)
                }
              }
            }else{
              console.log(this.currentSelectDeptInfo)
              if(this.currentSelectDeptInfo == "221027060"){
                this.valueIndex = this.formatter(res.data.data.total.toFixed(2)+5806377)
                console.log(11)
              }else if(this.currentSelectDeptInfo == "221027059"){
                this.valueIndex = this.formatter(res.data.data.total.toFixed(2)+5806377)
                console.log(22)
              }else{
                this.valueIndex = this.formatter(res.data.data.total.toFixed(2))
                this.maindata = res.data.data.total
                console.log(33)
              }
            }
            
          }
          
          console.log(this.valueIndex)
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      
      let data = {
        deptCode:this.$store.state.financeView,
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self;
          this.detailInfo.surplus = result.surplus;
          console.log(result.self)
         
          this.detailInfo.storage = result.carbon;
          let arr = ['自发自用','余电上网','碳排放',];
          this.newarr1 = []
        
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 5)/ 100, unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 4)/ 100, unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 100, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
      
          console.log(this.detailInfo)
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    getData3() {
      
      let data = {
        deptCode:"221027388"
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self;
          this.detailInfo.surplus = result.surplus;
          console.log(result.self)
         
          this.detailInfo.storage = result.carbon;
          let arr = ['自发自用','余电上网','碳排放',];
          this.newarr1 = []
        
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 5)/ 100, unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 4)/ 100, unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 100, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
      
          console.log(this.detailInfo)
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
  },
  mounted() {
    this.getData()
    this.getData2()
  }
}
</script>
            
<style lang="less" scoped>
.powerStation {
  .contant {
    display: flex;
    justify-content: space-around;

    &>div {
      img {
        display: block;
        width: 366px;
        height: 364px;
        padding: 26px 24px;
      }

      .textPIN {
        display: flex;
        flex-direction: column;
        text-align: center;

        &>span:nth-child(1) {
          opacity: 0.6;
          font-size: 20px;
          font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
          font-weight: 400;

          color: #ffffff;
          line-height: 22px;
        }

        &>span:nth-child(2) {
          font-size: 50px;
          font-family: ABBvoice CNSG, ABBvoice CNSG-Bold;
          font-weight: 700;

          color: #ffffff;
          line-height: 79px;
        }
      }
    }
  }

  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    padding: 9.5px 0 0 25px;
    margin: 10px 0;
    flex-direction: column;
    background-image: url(../../../assets/icon/icon/lang.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    &>span:nth-child(1) {
      height: 22px;
      opacity: 0.6;
      font-size: 16px;
      font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 22px;
    }

    &>span:nth-child(2) {
      height: 44px;
      font-size: 32px;
      font-family: ABBvoice CNSG, ABBvoice CNSG-Bold;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
      line-height: 44px;
    }
  }
}
</style>