<template>
  <div class="reduction">
    <siteName :objname="objname"></siteName>
    <div class="contant">
      <ul>
        <li v-for="(item, index) in textGraup" :key="index">
          <span>{{ item.name }}</span>
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import siteName from "./site";
export default {
  name: 'reduction',
  props: {},
  components: { siteName },
  data() {
    return {
      maindata:"",
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        TYPE1: "总发电量",
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      detailInfo: {
        chart1: {},
        chart2:{}
      },
      newarr1:[],
      objname: {
        name: 'Energy And Emissions Reduction',
        site: '节能减排'
      },
      carbon:""
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.financeView;
    },
    // 当前Dept信息
    textGraup(){
      let arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((parseInt(this.maindata)*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.carbon
        }
      ]

      if(this.currentSelectDeptInfo =="221027582"){

      }else if(this.currentSelectDeptInfo =="221027586"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((157263*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((157263*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((157263*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((157263*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((157263*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((157263*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((157263*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027585"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((143865*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((143865*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((143865*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((143865*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((143865*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((143865*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((143865*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027584"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((440983*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((440983*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((440983*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((440983*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((440983*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((440983*0.005023).toFixed(2))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((440983*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027583"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((90059*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((90059*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((90059*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((90059*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((90059*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((90059*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((90059*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027593"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((156924*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((156924*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((156924*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((156924*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((156924*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((156924*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((156924*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027591"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((344892*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((344892*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((344892*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((344892*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((344892*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((344892*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((344892*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027592"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((427858*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((427858*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((427858*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((427858*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((427858*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((427858*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((427858*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027595"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((137061*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((137061*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((137061*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((137061*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((137061*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((137061*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((137061*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027597"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((162866*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((162866*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((162866*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((162866*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((162866*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((162866*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((162866*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027588"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((239102*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((239102*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((239102*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((239102*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((239102*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((239102*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((239102*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027589"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((476035*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((476035*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((476035*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((476035*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((476035*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((476035*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((476035*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027596"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((188946*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((188946*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((188946*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((188946*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((188946*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((188946*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((188946*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027590"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((120006*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((120006*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((120006*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((120006*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((120006*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((120006*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((120006*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027587"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((166663*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((166663*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((166663*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((166663*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((166663*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((166663*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((166663*0.0009830).toFixed(2))
        }
      ]
      }else if(this.currentSelectDeptInfo =="221027594"){
        arr = [
        {
          name: '节煤(吨)',
          value: this.toThousandsSeparator((418587*0.000328).toFixed(2))
        },
        {
          name: '减排氮氧化物 (吨)',
          value: this.toThousandsSeparator((418587*0.000015).toFixed(2))
        },
        {
          name: '节约净水 (吨)',
          value: this.toThousandsSeparator((418587*0.004).toFixed(2))
        },
        {
          name: '减排粉尘 (吨)',
          value: this.toThousandsSeparator((418587*0.000272).toFixed(2))
        },
        {
          name: '减排二氧化硫 (吨)',
          value: this.toThousandsSeparator((418587*0.00003).toFixed(2))
        }, {
          name: '等于植树 (棵)',
          value: this.toThousandsSeparator((418587*0.005023).toFixed(0))
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.toThousandsSeparator((418587*0.0009830).toFixed(2))
        }
      ]
      }
      return  arr
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };;
      return obj[TYPE];
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      // if(this.searchObj.ITEM == ""){
      //   this.detailInfo = {}
      //   var chart1 = {
      //     categoryData: [],
      //     seriesData: []
      //   }
      //   this.detailInfo.chart1 = chart1
      //   this.initEchart()
      //   return
      // }
      //   let data = {
      //     "deptCode": this.currentSelectDeptInfo.CODE,
      //     "assetNumber":this.searchObj.ITEM,
      //     "check": this.searchObj.TYPE1
      //   }
      let data = {
        deptCode: this.$store.state.financeView,
    
        check: this.searchObj.TYPE1,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      console.log(data);
      this.detailInfo = {};
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/ranking",
        method: "post",
        data,
      })
        .then((res) => {
          let value = 0
          for(let item of res.data.data.seriesData[0].value){
            value =value+item
          }
          this.maindata = value
          console.log(this.maindata)
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      
      let data = {
        deptCode:this.$store.state.financeView,
        
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self.toString();
          this.detailInfo.surplus = result.surplus.toString();
          this.detailInfo.storage = result.carbon.toString();
          this.carbon = result.carbon.toString();
          let arr = ['自发自用','余电上网','碳排放',];
          this.newarr1 = []
        
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 5)/ 100, unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 4)/ 100, unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 100, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
      
          console.log(this.detailInfo)
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
  },
  mounted() {
    this.getData()
    this.getData2()
  }
}
</script>
              
<style lang="less" scoped>
.reduction {
  width: 100%;

  .contant {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 20px;
      

      li {
        margin: 10px;
        height: 85px;
        display: flex;
        flex-direction: column;
        background-image: url(../../../assets/icon/icon/lang01.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        padding: 9.5px 0 0 25px;

        &>span:nth-child(1) {
          opacity: 0.6;
          font-size: 16px;
          font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 22px;
        }

        &>span:nth-child(2) {
          font-size: 32px;
          font-family: ABBvoice CNSG, ABBvoice CNSG-Bold;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
          line-height: 44px;
        }

      }


    }
  }

}
</style>