<template>
  <div class="graphcont">
    <siteName :objname="objname"></siteName>
    <div ref="graphcont" id="graphcont"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "./site";
export default {
  name: 'graphcont',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name: "按小时",
        },
        
        {
          code: "按月",
          name: "按天",
        },
        {
          code: "按年",
          name: "按月",
        },
      ],
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      newarr1:[],
      objname: {
        name: 'Energy Income',
        site: '能源收益'
      },

    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.financeView;
    },
    currentTime() {
      return this.$store.state.timer;
    },
  },
  methods: {
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };;
      return obj[TYPE];
    },
    //每月报警预警数
    getData() {
      
      let data = {
        // deptCode:"221027091",
        deptCode:this.$store.state.financeView,
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
            
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          let seriesData = []
          let profit = []
          for(let index in result.seriesData[0].value){
            let num = result.seriesData[0].value[index]+result.seriesData[1].value[index]
            seriesData.push(num)
          }
          // alert(this.currentSelectDeptInfo)
          if(this.currentSelectDeptInfo == "221027388"){
            if(this.currenttype == '按天'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                  profit.push((item-77)/item*100)
                }
                
              }
            }
            if(this.currenttype == '按月'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-935)/item*100)
                }
              }
            }
            if(this.currenttype == '按年'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-28061)/item*100)
                }
              }
            }
          }else if(this.currentSelectDeptInfo == "221027423"){
            if(this.currenttype == '按天'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-22)/item*100)
                }
              }
            }
            if(this.currenttype == '按月'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-674)/item*100)
                }
              }
            }
            if(this.currenttype == '按年'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-20225)/item*100)
                }
              }
            }
          }else if(this.currentSelectDeptInfo == "221027422"){
            if(this.currenttype == '按天'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-1.2)/item*100)
                }
              }
            }
            if(this.currenttype == '按月'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-37)/item*100)
                }
              }
            }
            if(this.currenttype == '按年'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-1140)/item*100)
                }
              }
            }
          }else if(this.currentSelectDeptInfo == "221027390"){
            if(this.currenttype == '按天'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-7.57)/item*100)
                }
              }
            }
            if(this.currenttype == '按月'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-223)/item*100)
                }
              }
            }
            if(this.currenttype == '按年'){
              for(let item of seriesData){
                if(item == 0){
                  profit.push(0)
                }else{
                profit.push((item-6697)/item*100)
                }
              }
            }
            
          } 

          if(this.currentSelectDeptInfo =="221027582"){
              this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[450523.00,473974.00,656421.00,756799.00,935929.00,796922.00,882093.00]
              }]
              profit = [59.21,61.23,70.08,74.04,79.01,75.35,77.73]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027586"){
              this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[18123.48,28181.23,38919.31,19115.96,57470.85,49110.12,24637.18]
              }]
              profit = [47.23,66.07,75.43,49.97,83.36,80.53,61.18]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027585"){
              this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[17516.89,14309.33,25429.32,20104.05,22315.52,21646.71,27658.80]
              }]
              profit = [44.32,31.83,61.64,51.48,56.29,54.94,64.73]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027584"){
              this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[42137.47,49888.46,63106.30,76186.52,79469.21,81936.04,85135.43]
              }]
              profit = [60.77,66.87,73.81,78.30,79.20,79.83,80.58]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027583"){
              this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[10547.23,13015.40,20958.47,25553.09,26774.18,24699.05,26393.74]
              }]
              profit = [35.37,47.63,67.48,73.32,74.54,72.40,74.17]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027593"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[19594.40,10838.20,14194.00,16321.69,21327.95,39950.25,50346.98]
              }]
              profit = [59.60,26.97,44.23,51.50,62.89,80.19,84.28]
              this.detailInfo.chart1.seriesData = seriesData
        
          }else if(this.currentSelectDeptInfo =="221027591"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[55328.54,53181.07,75744.68,86392.05,102728.02,59937.78,82052.26]
              }]
              profit = [69.42,68.18,77.66,80.41,83.53,71.77,79.38]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027592"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[66565.98,69052.17,86034.62,104487.28,126705.16,67868.76,92573.08]
              }]
              profit = [64.19,65.48,72.29,77.18,81.18,64.87,74.25]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027595"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[32736.10,23964.85,33544.78,36678.28,43515.34,23430.05,32231.76]
              }]
              profit = [78.82,71.06,79.33,81.09,84.06,70.40,78.48]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027597"){
          this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[22946.85,27119.99,36330.72,39497.79,51951.90,28025.93,39841.89]
              }]
              profit = [60.92,66.94,75.32,77.30,82.74,68.01,77.49]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027588"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[16136.88,18081.75,31705.49,37637.68,44662.21,46459.71,52720.93]
              }]
              profit = [17.58,26.44,58.05,64.66,70.22,71.37,74.77]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027589"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[55019.75,60735.08,80267.81,79008.56,110025.36,142508.85,148341.86]
              }]
              profit = [56.03,60.17,69.86,69.38,78.01,83.02,83.69]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027596"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[0,0,15528.28,51760.94,67489.01,54404.42,74519.99]
              }]
              profit = [0,0,18.35,75.50,81.21,76.69,82.99]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027590"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[15700.57,21308.72,23158.74,28523.97,28188.19,27893.75,23330.50]
              }]
              profit = [55.69,67.35,69.96,75.61,75.32,75.06,70.18]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027587"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[10608.32,11006.69,16953.83,19327.74,20217.08,49049.43,14574.43]
              }]
              profit = [30.36,32.88,56.42,61.77,63.46,84.94,49.31]
              this.detailInfo.chart1.seriesData = seriesData
          }else if(this.currentSelectDeptInfo =="221027594"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
              seriesData = [{
                name:"成本",
                value:[67560.29,73291.47,94544.51,116203.55,133088.78,80001.27,107734.52]
              }]
              profit = [63.48,66.34,73.90,78.77,81.46,69.16,77.10]
              this.detailInfo.chart1.seriesData = seriesData
          }
          

          this.detailInfo.seriesData = seriesData
          this.detailInfo.profit = profit
          this.initEchart()
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      let floatPart2 = "";
      let floatPart3 = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分

        // if(valueArray[2]){
        //   floatPart2 = valueArray[2].toString()
        // }
        // if(valueArray[3]){
        //   floatPart3 = valueArray[3].toString()
        // }
        return intPartFormat + "." + floatPart+ floatPart2+ floatPart3;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      console.log(TYPE)
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };;
      return obj[TYPE];
    },
    initEchart2() {
   
      var dom = document.getElementById('graphcont');
      
      let chartInit = echarts.init(dom);
      var { categoryData, seriesData } = this.detailInfo.chart1;
      
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          top: '20%',
          bottom: '0%',
          left: '0%',
          right: '5%',
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          //orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis:[ {
            type: 'value',
            name: '(元)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            min: 0,
            // max: 250,
            // interval: 50,
          },
          {
            name: "",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
            // max: 100
          }
        ],

        // 控住柱状图样式
        // series:series,
        series: [
          {
            type: "bar",
            barWidth:10,
            name: '收入',
            data: seriesData[0].value,
            
            
          }
        ],
      };
      
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    initEchart() {
     
      var dom = document.getElementById('graphcont');
      let chartInit = echarts.init(dom);
      var { categoryData, seriesData } = this.detailInfo.chart1;
      console.log(seriesData)
      var option = {
        animation: true,
        layoutAnimation: false,
        backgroundColor: 'rgba(0,0,0,0)',
        // tooltip: {},
        grid: {
          top: '20%',
          bottom: '0%',
          left: '0%',
          right: '5%',
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          //orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: [
          {
            type: 'category',
            data: categoryData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(元)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
          },
          {
            name: "%",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
            // max: 100
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        series: [
          {
            name: '收入',
            type: 'bar',
            yAxisIndex: 0,
            barWidth:10,
            
            data: seriesData[0].value
          },
          {
            name: '利润率',
            yAxisIndex: 1,
            type: 'line',
            
            tooltip: {
              valueFormatter: function (value) {
                return parseFloat(value).toFixed(2) + ' %';
              }
            },
            data: this.detailInfo.profit
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
                
<style lang="less" scoped>
.graphcont {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#graphcont {
  width: 100%;
  height: calc(100% - 50px);
}
</style>
                