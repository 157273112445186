import { render, staticRenderFns } from "./screen.vue?vue&type=template&id=3580bab8&scoped=true"
import script from "./screen.vue?vue&type=script&charset=utf-8&lang=js"
export * from "./screen.vue?vue&type=script&charset=utf-8&lang=js"
import style0 from "./screen.vue?vue&type=style&index=0&id=3580bab8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3580bab8",
  null
  
)

export default component.exports