<template>
  <div class="graphleft">
    <siteName :objname="objname"></siteName>
    <div ref="graphleft" id="graphleft"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "./site";
export default {
  name: 'graphleft',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name: "按小时",
        },
        
        {
          code: "按月",
          name: "按天",
        },
        {
          code: "按年",
          name: "按月",
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Electricity Generation',
        site: '发电量'
      },

    }
  },
  watch: {
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
  
        }
      },
      deep: true,
      immediate: true,
    },
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.financeView;
    },
    currentTime() {
      return this.$store.state.timer;
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      let floatPart2 = "";
      let floatPart3 = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分

        // if(valueArray[2]){
        //   floatPart2 = valueArray[2].toString()
        // }
        // if(valueArray[3]){
        //   floatPart3 = valueArray[3].toString()
        // }
        return intPartFormat + "." + floatPart+ floatPart2+ floatPart3;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    initEchart() {
      console.log(this.detailInfo)
      var dom = document.getElementById('graphleft');
      let chartInit = echarts.init(dom);
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // tooltip: {},
        grid: {
          top: '20%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          //orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: {
          type: 'category',
          data: this.detailInfo.chart1.categoryData,
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: {
            type: 'value',
            name: '(kWh)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // min: 0,
            // max: 250,
            // interval: 50,
        },
        series: [
          {
            data: this.detailInfo.chart1.seriesData[0].value,
            type: 'line',
            name:'发电量',
            itemStyle: {
              
              color: {
                type: 'linear',
                x: 0,  //右
                y: 1,  //下
                x2: 0,  //左
                y2: 0,  //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,135,254,0)'
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(79,172,254,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(79,172,254,1)' 
                  }
                ]
              }
            },
            areaStyle: {
              color: 'rgba(117,198,255,0.8)'
            },
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    getData() {

      let data = {
        assetNumber:"",
        // deptCode:"221027091",
        deptCode: this.$store.state.financeView,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/statistics",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.detailInfo = {};
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          
          if(result.seriesData.length == 0){
            let arr = []
            for(let index in result.categoryData){
              arr.push(0)
            }
            let obj = {
              name:"",
              value:arr
            }
            chart1.seriesData.push(obj)
          }
          console.log(chart1)
          console.log("11111111111111111")

          this.detailInfo.chart1 = chart1;
          console.log(this.detailInfo.chart1.seriesData[0].value)
          console.log(this.detailInfo.chart1.categoryData)

          if(this.currentSelectDeptInfo =="221027582"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [520428,483434,711278,900257,1055713,996041,1139225]
          }else if(this.currentSelectDeptInfo =="221027586"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [24202,20473,27636,33676,37878,34376,40496]
          }else if(this.currentSelectDeptInfo =="221027585"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [24240,20684,34241,38580,39518,37421,34646]
          }else if(this.currentSelectDeptInfo =="221027584"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [9793,12296,19765,23513,24692,22932,23848]
          }else if(this.currentSelectDeptInfo =="221027583"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [73023,64982,83396,106305,113277,110778,115021]
          }else if(this.currentSelectDeptInfo =="221027593"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [26006,23060,30200,34727,42931,34165,43404]
          }else if(this.currentSelectDeptInfo =="221027591"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [55641,46187,67088,80478,95498,77804,98864]
          }else if(this.currentSelectDeptInfo =="221027592"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [66029,62893,79636,97476,121824,100939,128033]
          }else if(this.currentSelectDeptInfo =="221027595"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [22486,19624,27661,30740,36550,28885,37636]
          }else if(this.currentSelectDeptInfo =="221027597"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [25475,24094,31993,36484,44820,36624,50054]
          }else if(this.currentSelectDeptInfo =="221027588"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [21986.4,24091.2,46304.4,61996.8,84723.6,85555.2,103622.4]
          }else if(this.currentSelectDeptInfo =="221027589"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [64852,67730,101798,114662,126993,173551,162734]
          }else if(this.currentSelectDeptInfo =="221027596"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [0,0,21353,71997,95596,173551,162734]
          }else if(this.currentSelectDeptInfo =="221027590"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [20636,16434,26526,28326,28084,26101,24418]
          }else if(this.currentSelectDeptInfo =="221027587"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [20029,20276,36096,44024,46238,50898,49596]
          }else if(this.currentSelectDeptInfo =="221027594"){
            this.detailInfo.chart1.categoryData = ['2023.1','2023.2','2023.3','2023.4','2023.5','2023.6','2023.7']
            this.detailInfo.chart1.seriesData[0].value = [66030,60610,77585,97272,117090,99523,127405]
          }

          let newarr = [];
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

  },
  mounted() {
    this.getData()
  }
}
</script>
                
<style lang="less" scoped>
.graphleft {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#graphleft {
  width: 100%;
  height: calc(100% - 50px);
}
</style>
                