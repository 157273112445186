<template>
  <div class="graphright">
    <siteName :objname="objname"></siteName>
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <div style="display: flex;flex-direction: column;align-items: center;width: 200px;">
          <img :src="item.img" style="" alt="" />
          <span style="font-size: 0.15rem;color:#b6b6b6;margin-top:20px">{{ item.name }}<span style="font-size: 0.15rem;">{{ item.unit }}</span></span>
          <span style="font-size: 0.28rem;font-weight: 600;margin-top: 10px;">
            {{ item.value }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "./site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
        chart2:{}
      },
      newarr1:[],
      objname: {
        name: 'Financial Information',
        site: '财务总览'
      },

    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.financeView;
    },
    listData(){
        let arr = [
          {
            name: '固定资产金额',
            unit:"",
            value: "￥0",
            img: require('@/assets/imgs/big/icon27.png'),
            site: '个'
          },
          {
            name: '预计回本周期（年）',
            value: "0",
            unit:"",
            img: require('@/assets/imgs/big/icon28.png'),
            site: '个'
          },
          {
            name: '预计项目收益率',
            value: "0",
            unit:"",
            img: require('@/assets/imgs/big/icon29.png'),
            site: '套'
          },
          {
            name: '利润率',
            unit:"",
            value: "0",
            img: require('@/assets/imgs/big/icon30.png'),
            site: '个'
          },
        
        ]
        console.log(this.currentSelectDeptInfo)
        if(this.currentSelectDeptInfo =="221027582"){
             arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥44,506,510",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "12",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "80%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "72.75%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027586"){
             arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥2,217,712",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "8.84",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "7.92%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "71.58%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027585"){
             arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥2,272,685",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "17.99",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "2.55%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "54.17%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027584"){
             arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥4,122,305",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.45",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "11.64%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "75.79%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027583"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,671,452",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "9.41",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "7.07%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "67.75%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027388"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥7,077,546",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "—",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027423"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥5,303,044",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "12.08",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "4.30%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "51.04%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027422"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥2,48,989",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "12.08",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "4.30%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "64.81%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027390"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,525,513",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.26",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "10.85%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "59.84%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027593"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,744,914",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "5.28",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "14.68%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "67.89%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027591"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥3,807,611",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "5.21",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "13.75%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "77.02%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027592"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥5,413,548",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "5.67",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "13.24%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "72.79%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027595"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,505,082",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "5.86",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "13.35%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "78.53%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027597"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,983,025",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.26",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "10.85%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "74.46%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027588"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥2,906,168",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.79",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "11.19%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "62.37%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027589"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥5,545,452",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "4.96",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "13.59%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "74.95%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027596"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥2,526,722",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.26",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "10.85%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "75.96%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027590"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,590,194",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "8.12",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "8.47%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "71.03%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027587"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥1,523,469",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "7.43",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "9.91%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "63.51%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027594"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥5,676,164",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "6.71",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "12.17%",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "74.31%",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027060"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥7,077,546",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "—",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }else if(this.currentSelectDeptInfo =="221027059"){
            arr = [
              {
                name: '固定资产金额',
                unit:"",
                value: "￥7,077,546",
                img: require('@/assets/imgs/big/icon27.png'),
                site: '个'
              },
              {
                name: '预计回本周期（年）',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon28.png'),
                site: '个'
              },
              {
                name: '预计项目收益率',
                value: "—",
                unit:"",
                img: require('@/assets/imgs/big/icon29.png'),
                site: '套'
              },
              {
                name: '利润率',
                unit:"",
                value: "—",
                img: require('@/assets/imgs/big/icon30.png'),
                site: '个'
              },
            
            ]
          }
        return arr
    },
  },
  methods: {
    getData2() {
      // this.detailInfo = {
      //     cost1: '51276.33',
      //     cost2: '5270.33',
      //     comparePercent1: -11.2,
      //     comparePercent2: 11.2,
      // }
      let data = {
        deptCode: this.$store.state.financeView,
      };
      Object.assign(data, this.transformSearch2());
      if (this.searchObj.TYPE == "按天") {
        data.type = "day15m";
        data.queryType = data.type;
        delete data.type;
      } else {
        data.queryType = data.type;
        delete data.type;
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/cost",
        method: "post",
        data: data,
      })
        .then((res) => {
          let result = res.data.data;
          // this.detailInfo = {}
          this.detailInfo.cost1 = result.cost1;
          this.detailInfo.cost2 = result.cost2;
          this.detailInfo.comparePercent1 = result.comparePercent1;
          this.detailInfo.comparePercent2 = result.comparePercent2;
          console.log(result);
          var chart2 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart2 = chart2;
          this.initEchart();
          
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      let floatPart2 = "";
      let floatPart3 = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分

        // if(valueArray[2]){
        //   floatPart2 = valueArray[2].toString()
        // }
        // if(valueArray[3]){
        //   floatPart3 = valueArray[3].toString()
        // }
        return intPartFormat + "." + floatPart+ floatPart2+ floatPart3;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      var data = {
        // deptCode: deptCode,
        deptCode: this.$store.state.financeView,
        // queryType:"year",
        // startDate:"2022-1-01 00:00:00",
        // endDate: "2022-12-10 23:59:59"
      };
      Object.assign(data, this.transformSearch());
      
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
           
            this.detailInfo = {};
            let newarr = [];
            this.detailInfo.average = res.data.data.totalRate.toString();
            this.detailInfo.greenTotal = res.data.data.totalGreenPower.toString();
            this.detailInfo.total = res.data.data.totalPower.toString();
            var chart1 = {
              categoryData: res.data.data.date,
              seriesData: res.data.data.seriesData,
            };
            this.detailInfo.chart1 = chart1;
          console.log(this.detailInfo)
           
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
       
    },
    initEchart() {
      var dom = document.getElementById('graphright');
      let chartInit = echarts.init(dom);
      console.log(this.detailInfo)
      let arr = []
      for(let index in this.detailInfo.chart1.seriesData[0].value){
        let value = ""
        console.log(this.detailInfo.chart1.seriesData[0].value[index])
        console.log(this.detailInfo.chart1.seriesData[1].value[index])
        console.log(this.detailInfo.chart1.seriesData[0].value[index]-this.detailInfo.chart1.seriesData[1].value[index])
        value = (this.detailInfo.chart1.seriesData[0].value[index]-this.detailInfo.chart1.seriesData[1].value[index]).toFixed(2)
        // if(this.detailInfo.chart1.seriesData[0].value[index]&&this.detailInfo.chart1.seriesData[1].value[index]){
        //   value = this.detailInfo.chart1.seriesData[0].value[index]-this.detailInfo.chart1.seriesData[1].value[index]
        // }else if(){

        // }
        arr.push(value)
      }
      
      console.log(arr)
      var option = {
        animation: true,
        layoutAnimation: false,
        backgroundColor: 'rgba(0,0,0,0)',
        // tooltip: {},
        grid: {
          top: '20%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          //orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: [
          {
            type: 'category',
            data: this.detailInfo.chart1.categoryData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(kWh)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
          },
          {
            name: "(元)",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
            // max: 100
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        series: [
          {
            name: '市电',
            type: 'bar',
            yAxisIndex: 0,
            barWidth:10,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' kW';
              }
            },
            data: arr
          },
          {
            name: '光伏',
            type: 'bar',
            yAxisIndex: 0,
            barWidth:10,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' kW';
              }
            },
            data: this.detailInfo.chart1.seriesData[1].value
          },
          {
            name: '金额',
            yAxisIndex: 1,
            type: 'line',
            
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            data: this.detailInfo.chart2.seriesData[0].value
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    
  }
}
</script>
                
<style lang="less" scoped>
.graphright {
  width: 100%;
  height: 100%;
  
}
ul {
    color: #fff;
    width: 105%;
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &>li {
      display: flex;
      align-items: center;
      height: 1.5rem;
      width: 2rem;
      background-image: url(../../../assets/icon/icon/lang01.png);
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      &>div {

        &>img {
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
          
        }
      }

      // &>div:nth-child(1) {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }

      &>div:nth-child(2) {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
          flex: 1;
          display: inline-flex;
        }

        &>span:nth-child(1) {
          margin: 10px 0;
          font-size:  0.2rem;
          line-height: 0.2rem;
          color: rgba(243,243,243,0.8);
        }

        &>span:nth-child(2) {
          font-size: 0.2rem;
          line-height: 0.3rem;

          &>p {
            font-size: 0.17rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
#graphright {
  width: 100%;
  height: calc(100% - 50px);
}
</style>
                